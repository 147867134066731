.aboutHeadings {
  font-weight: 600 !important;
  font-family: Rajdhani !important;
  font-size: 60px;
}
.aboutText {
  font-weight: 500;
  font-family: Yantramanav;
  font-size: 19px;
  opacity: 0.9;
  text-align: justify !important;
}
.contactPageHeading{
  font-weight: 600 !important;
  font-family: Rajdhani !important;
  font-size: 60px;
  text-align: center;
  
}
.contactOrangeTxt{
  font-weight: 600 !important;
  font-family: Rajdhani !important;
  font-size: 40px;
  text-align: center !important;
}
.contactBlueTxt{
  font-weight: 400 !important;
  font-family: Yantramanav !important;
  font-size: 22px;
  padding-top: 40px !important;
}
.contactPageHeadingMob{
  font-weight: 600 !important;
  font-family: Rajdhani !important;
  font-size: 40px !important;
  text-align: center;
  display: none;
}

.projectsOtherHeading{
  font-weight: 600 !important;
  font-family: Rajdhani !important;
  font-size: 60px !important;
  text-align: center;
}

.projectCard{
  border: 2px solid;
  height: 100%;
}
.projectHeadings{
  text-align: center;
  text-decoration: underline !important;
  font-weight: bold !important;
  font-family: Rajdhani !important;
  font-size: 30px !important;
  padding-top: 10px;
}
.projectText{
  font-size: 18px !important;
  font-weight: 400;
  font-family: Yantramanav !important;
  margin-bottom: 20px !important;
}

.memberName{
  font-weight: 700 !important;
  font-family: Rajdhani !important;
  font-size: 65px !important;
}
.memberPost{
  font-weight: 700 !important;
  font-family: Rajdhani !important;
  /* font-size: 65px !important; */
}

.memberAbtTxt{
  font-size: 20px !important;
  font-weight: 400;
  font-family: Yantramanav !important;
  margin-bottom: 20px !important;
  text-align: justify !important;
}

.downloadHover{
  transition: 0.4s all ease-in-out !important;
}

.downloadHover:hover{
  transform: scale(1.1) !important;
}
.listServ li p{
  margin-top: -15px !important;
}

@media (max-width: 550px) {
  .aboutHeadings {
    font-size: 40px !important;
  }
  .aboutText {
    font-size: 17px !important;
  }
  .contactPageHeading{
    display: none !important;
  }
  .contactPageHeadingMob{
    display: block !important;
  }
  .contact_container{
    margin-top: 40px !important;
  }
  .projectText{
    font-size: 20px !important;
    margin-left: 9px !important;
    padding-right: 10px !important;
  }
  .memberName{
    font-size: 40px !important;
  }
  .memberPost{
    font-size: 40px !important;
  }
  .memberAbtTxt{
    font-size: 18px !important;
  }

  .memberImg{
    height: 280px !important;
  }
  .bgImgExtrapage{
    background-image: none !important;
  }
}


@media (max-width: 768px) {
  .about_container {
    margin-top: 40px !important;
  }
  .servicesPageImg{
    width: 100% !important;
  }
  .righParaCont{
    margin-top: 25px !important;
  }
}

@media(max-width: 765px){
  .service_detail_col2{
    margin-top: auto !important;
  }
}

@media (max-width: 1310px) {
  .servicesPageImg{
    width: 100% !important;
    height: auto !important;
  }
}
