.cont_hero {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
}
.hero_heading {
  font-family: Rajdhani !important;
  font-weight: bold !important;
  font-size: 80px !important;
}
.subTxt_hero {
  font-family: Yantramanav !important;
  font-weight: 300 !important;
  font-size: 30px !important;
}

.card_container {
  width: 100%;
  height: 100%;
  position: relative;
  /* color: #fff !important; */
}
.card_heading {
  font-family: Rajdhani !important;
  font-weight: 500 !important;
  font-size: 30px !important;
}
.card_text {
  font-family: Yantramanav !important;
  font-weight: 300 !important;
  font-size: 19px !important;
}

.card_img {
  position: absolute;
  height: 100px;
  width: 100px;
  object-fit: contain;
  bottom: 0px;
  right: 0px;
  opacity: 0.2;
}

.heading_aboutSec {
  font-size: 60px;
  font-family: Rajdhani;
  font-weight: 700;
  text-align: right !important;
  border-right: 2px solid rgb(230, 227, 227);
}
.heading_aboutSec_media {
  font-size: 50px;
  font-family: Rajdhani;
  font-weight: 700;
  text-align: center !important;
  display: none !important;
}
.aboutus_heading {
  font-size: 24px;
  font-family: Rajdhani;
  font-weight: 600;
  text-align: right !important;
  text-transform: uppercase;
}
.patternImg {
  position: absolute !important;
  height: 250px;
  width: 260px;
}
.aboutSecImg {
  z-index: 1 !important;
  position: absolute;
  height: 420px !important;
  left: 50px !important;
  top: 40px !important;
}
.sinceBox {
  z-index: 2 !important;
  position: relative;
  height: 100% !important;
  top: 380px !important;
  left: 220px !important;
  width: 320px !important;
  border-bottom: 5px solid orangered !important;
  padding: 0px !important;
}
.sinceBoxMedia{
    height: auto;
    display: none !important;
    width: 80% !important;
    border-bottom: 5px solid orangered !important;
}
.sinceTxt {
  font-family: Rajdhani !important;
  font-weight: 200 !important;
  font-size: 30px !important;
  margin-bottom: -20px !important;
}
.yearTxt {
  font-family: Rajdhani !important;
  font-weight: 500 !important;
  font-size: 64px !important;
  margin-bottom: -4px !important;
  padding-left: 20px;
}
.about_paragraph {
  font-family: Yantramanav;
  font-weight: 500;
  font-size: 19px;
}
.abousus_main {
  height: 100vh !important;
}
.servicesList li::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1.2em;
  margin-left: -1em;
}
.servicesList li {
  list-style: none !important;
}

.servicesHeading {
  font-family: Rajdhani;
  font-size: 20px;
  font-weight: 700;
  /* text-align: left; */
}
.fullStackHeading {
  font-family: Rajdhani;
  font-size: 70px;
  font-weight: 700;
}
.servicesSubTxt {
  font-family: Yantramanav;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.footerHeading {
  font-weight: 600;
  font-family: Rajdhani;
  font-size: 25px;
}
.footerTxt {
  font-weight: 400;
  font-family: Yantramanav;
  font-size: 18px;
}




.card_container, .teamCard{
  transition: 0.4s all ease-in-out !important;
}

.card_container:hover{
  transform: scale(1.1);
  transition: 0.4s all ease-in-out !important;
}
.teamCard:hover{
  transform: scale(1.1);
  transition: 0.4s all ease-in-out !important;
}

.clientsLogo{
  transform: scale(1.1);
  transition: 0.4s all ease-in-out !important;
}
.clientsLogo{
  transition: 0.4s all ease-in-out !important;
  cursor: pointer !important;
}

.teamCard{
  height: 100% !important;
}

@media (max-width: 984px) {
  .featuredProjectCard {
    margin-top: -30px !important;
  }
}

@media (max-width: 581px) {
  .hero_heading {
    font-size: 46px !important;
  }
  .subTxt_hero {
    font-size: 25px;
  }
  .featuredProjectCard {
    margin-top: -30px !important;
  }
  .fullStackHeading {
    font-size: 40px !important;
  }
  .servicesSubTxt {
    font-size: 18px !important;
  }
  .featuredHeading {
    font-size: 50px !important;
  }
  .buildingsName {
    padding-top: 80px !important;
  }
  .ProjectManagementTxt {
    font-size: 18px !important;
  }
  .section_card_cont{
    margin-top: 30px !important;
  }
  .servicesSubTxt {
    text-align: justify !important;
    margin: 0px !important;
  }
  .mainHeadingSec{
    margin-left: 0px !important;
  }
}

@media (max-width: 768px) {
  .left_section2_about {
    display: none !important;
  }
  .heading_aboutSec_media {
    display: block !important;
  }
  .featuredProjectCard {
    margin-top: -30px !important;
  }
  .mainDivFeatured{
      margin-top: -23px !important;
  }
  .sinceBoxMedia{
      display: block !important;
  }
  .footerLogo{
    display: flex !important;
    justify-content: center !important;
    align-self: center !important;
    width: 100%;
  }
}
@media (max-width: 766px) {
  .abousus_main {
    height: 100% !important;
  }
  .featuredProjectCard {
    margin-top: -30px !important;
  }
  .clientsLogo{
    width: 80% !important;
  }
}

@media(max-width: 350px){
  .hero_mob_text{
    padding-top: 100px !important;
  }
  .hero_heading {
    font-size: 30px !important;
  }
  .subTxt_hero {
    font-size: 22px !important;
  }

}
