ul li a{
    font-family: Rajdhani;
    font-weight: 500;
    color: #fff !important;
    font-size: 18px !important;
}
.logo{
    clip-path: polygon(0 0, 100% 0, 91% 100%, 0% 100%);
        float: right !important;
        height: inherit !important;
        display: flex;
        justify-content: center;
        align-items: center;
         background: rgb(255, 255, 255);
}
.navbar_cont{
    padding: 0px !important;
    background: rgba(0, 34, 90, 0.7);
}


@media(max-width: 768px){
    .logo{
        width: 40% !important;
    }
    .collapseArea{
        width: 60% !important;
    }
}
